<template>
  <div>
    <div class="flex">
      <div class="flex">
        <p class="font-barlow font-medium text-xl text-grey-medium-2">
          Job Roles /
        </p>
        <p class="font-barlow font-bold text-xl text-grey-dark-2 ml-1">
          {{ selectedJobRole.name }}
        </p>
      </div>
      <div class="flex flex-grow justify-end">
        <Button
          text="Cancel"
          type="secondary"
          size="medium"
          data-testid="next-button"
          class="mr-1"
          @click.native="goBack"
        />
        <Button
          text="Next"
          type="primary"
          :iconRight="arrowRight"
          size="medium"
          data-testid="next-button"
          @click.native="goNext"
        />
      </div>
    </div>
    <div class="bg-white shadow rounded px-2 pt-3 mt-3 pb-4.5 mb-4">
      <div class="max-w-3/5">
        <p class="font-barlow font-bold text-mdl text-grey-light mb-2">
          Basic Details
        </p>
        <div class="px-2">
          <p class="text-mdh text-grey-dark-1">
            Please enter a unique job role name and a clear description of it.
            Adding job titles will optimize the setup assessment process for
            company admins by mapping employees to a coresponding job role.
          </p>
          <InputField
            class="mt-3"
            label="Job Role Name"
            :disabled=true
            :defaultValue="jobRoleName"
            type="text"
          />
          <TextArea
            class="mt-2"
            label="Description"
            :disabled=true
            :defaultValue="description"
          />
          <p class="mt-2 mb-1 text-mdh text-grey-dark-2">
            Add as many values as you need. Separate values with a comma.
          </p>
          <MultiTagInput
            label="Job Titles (Optional)"
            :disabled=true
            :initialValues="values"
          />
        </div>
        <p class="font-barlow font-bold text-mdl text-grey-light mb-2 mt-3">
          Status
        </p>
        <div class="px-2">
          <p class="text-md" :class="status ? 'text-green' : 'text-red'">
            {{ status ? "Active" : "Inactive" }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import arrowRight from "@/assets/img/icons/arrow-right-white.svg";
import InputField from "../../../components/InputField/InputField.vue";
import TextArea from "../../../components/TextArea/index.vue";
import MultiTagInput from "../../../components/MultiTagInput/index.vue";
import { mapState } from "vuex";

export default {
  name: "JobRoleView",
  components: { InputField, TextArea, MultiTagInput },
  data: () => ({ arrowRight }),
  computed: {
    ...mapState({
      selectedJobRole: (state) => state.companies.selectedJobRole,
    }),
    jobRoleName: {
      get() {
        return this.selectedJobRole.name;
      }
    },
    description: {
      get() {
        return this.selectedJobRole.description;
      }
    },
    status: {
      get() {
        return this.selectedJobRole.isActive;
      }
    },
    values: {
      get() {
        return this.selectedJobRole.jobTitles
          ? this.selectedJobRole?.jobTitles?.split(",")
          : [];
      }
    },
  },
  methods: {
    async goNext() {
      this.$router.push("/job-roles/management/benchmarks");
    },
    goBack() {
      this.$router.push("/job-roles");
    }
  },
  beforeRouteEnter(to, _, next) {
    to.meta.sidenav.title = "View Job Role";
    next();
  },
};
</script>
